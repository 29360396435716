.container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}

.controls {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-top: auto;

  &.hidden {
    display: none;
  }
}