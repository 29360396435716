@import 'src/styles/mixins';

.tag {
  padding: .25rem .5rem;
  border-radius: var(--wds-border-radius-small);
  display: flex;
  align-items: center;
  background: var(--ppapp-background-paper-4);
  gap: .5rem;

  &.interactive {
    cursor: pointer;

    &:hover {
      box-shadow: inset 0 0 0 1px var(--wds-on-surface-outline-hover);
    }
  }

  &.active {
    box-shadow: inset 0 0 0 1px var(--wds-on-surface-outline-hover);
  }
}

.color-bar {
  height: 1.25rem;
  width: .25rem;
  border-radius: 2px;

  &.default {
    background-color: var(--ppapp-ability-color);
  }

  @include mobile {
    height: 1rem;
  }
}

.stack {
  gap: .25rem;
}
