@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 23rem));
  grid-template-rows: repeat(2, 6.725rem);
  gap: .75rem;

  @include mobile {
    grid-template-columns: 100%;
    grid-template-rows: repeat(6, 5.5rem);
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  margin: 0 auto;
}

.item {
  cursor: pointer;
  padding: 1rem;
  border-radius: var(--wds-border-radius-extrasmall);
  display: grid;
  grid-template-columns: 8rem auto;
  gap: 1rem;
  overflow: hidden;

  &.empty {
    opacity: 0;
    cursor: initial;
    pointer-events: none;
  }

  @include mobile {
    padding: .5rem;
    grid-template-columns: 7rem auto;
    gap: .5rem;
  }

  &.disabled {
    cursor: initial;
    opacity: .3;
  }

  .match-title {
    @include truncate-text;
  }

  .thumbnail {
    width: 100%;
    border-radius: var(--wds-border-radius-extrasmall);
    object-fit: contain;
    margin: auto 0;
  }

  .info {
    display: flex;
    flex-flow: column nowrap;
    gap: .75rem;
    overflow: hidden;

    @include mobile {
      gap: .5rem;
    }
  }

  .dimmed-color {
    color: var(--wds-on-surface-secondary);
  }
}
