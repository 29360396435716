@import 'src/styles/mixins';

.container {
  width: 100%;
  padding: 2rem 0;
}

.wrapper {
  display: grid;
  max-width: 80rem;
  margin: 0 auto;
  padding-left: 2rem;
  grid-template-columns: minmax(20rem, 1fr) minmax(12rem, auto) minmax(auto, 20rem);
  gap: 4rem;

  background-image:
    linear-gradient(239.8deg, var(--ppapp-background) 17.88%, rgba(0, 0, 0, 0) 80.34%),
    url('../../../../styles/svg/green_stripes.svg');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 748px 279px;

  @include tablet {
    gap: 2rem;
  }

  @include mobile {
    background-image: none;
    padding: 0 1rem;
    gap: 1rem;
    grid-template-columns: 1fr;
  }
}

.title {
  font-family: 'OldschoolGroteskCondensed' !important;
  text-transform: uppercase;
}

.description {
  order: 1;
  display: flex;
  flex-flow: column;
  gap: 2rem;

  @include mobile {
    gap: 1rem;
  }

  .playstyle {
    margin-top: .75rem;
    max-width: 28rem;
  }
}

.pitch {
  order: 3;
  background-color: var(--ppapp-background);
}

.additional {
  order: 2;
  display: flex;
  flex-flow: column;

  @include mobile {
    align-items: flex-start;
  }
}

.background {
  background-color: var(--ppapp-background);
}

.edit-button {
  margin-right: auto;
}
