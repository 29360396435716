@import 'src/styles/mixins';

.container {
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;

  @include mobile {
    height: 100%;
    overflow: hidden;
    gap: 1.5rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex: 0 0 max-content;
  }

  .content {
    overflow-y: auto;
    flex: 1 1 100%;
  }
}