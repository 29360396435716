@import 'src/styles/mixins';

.container {
  display: flex;
  align-items: center;
  overflow-y: auto;
  gap: .5rem;

  @include hideScrollbar;

  * {
    flex-shrink: 0;
  }
}

.all-filter {
  min-height: 1.75rem; // comes from color bar size
}

.active {
  cursor: initial;
}