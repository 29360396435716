@import 'src/styles/mixins';

.collapsed {
  cursor: pointer;
  border-radius: var(--wds-border-radius-medium);
  position: relative;
  overflow: hidden;
  width: 3rem;
  height: 3rem;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.expanded {
  cursor: pointer;
  border-radius: var(--wds-border-radius-medium);
  padding: .5rem;
  display: grid;
  grid-template-columns: 3rem 11rem max-content;
  grid-template-rows: repeat(2, max-content);
  gap: .5rem;

  @include mobile {
    grid-template-columns: 3rem 1fr max-content;
  }

  .thumbnail-wrapper {
    position: relative;
    grid-row: 1/3;
    align-self: center;
    display: flex;
    align-items: center;
  }

  .title {
    @include truncate-text;
    grid-column: 2;
    grid-row: 1;
  }

  .abilities {
    display: flex;
    gap: .5rem;
    align-items: center;
    grid-column: 2;
    grid-row: 2;
  }

  .menu {
    grid-column: 3;
    grid-row: 1/3;
  }
}

.thumbnail {
  object-fit: cover;
  width: 3rem;
  height: 3rem;
  border-radius: var(--wds-border-radius-medium);
}

.active {
  cursor: initial;
  box-shadow: inset 0 0 0 1px var(--wds-on-surface-secondary);
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
