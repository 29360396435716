@mixin controls-section {
  background-color: rgba(0, 0, 0, .5);
  border-radius: var(--wds-border-radius-small);
  padding: .5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.controls-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.size-control {
  @include controls-section();
}

.position-control {
  height: 100%;
  color: rgba(255, 255, 255, .7) !important;
  cursor: grab;

  @include controls-section();

  &.moving {
    cursor: move;
  }
}
