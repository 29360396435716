@import 'src/styles/mixins';

.container {
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;

  @include mobile {
    gap: 1rem;
    overflow: hidden;
  }
}

.details {
  flex: 1 1 100%;
  overflow-y: auto;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    flex: 0 0 max-content;
  }
}