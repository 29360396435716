@import 'src/styles/mixins';

.container {
  border-radius: var(--wds-border-radius-medium);
  padding: .25rem;
  display: grid;
  grid-template-columns: 3rem 11rem max-content;
  grid-template-rows: repeat(2, max-content);
  gap: .5rem;

  @include mobile {
    grid-template-columns: 3rem 1fr max-content;
  }

  .thumbnail {
    grid-row: 1/3;
    object-fit: cover;
    width: 3rem;
    height: 3rem;
    margin: auto;
    border-radius: var(--wds-border-radius-medium);
  }  

  .title {
    @include truncate-text;
    grid-column: 2;
    grid-row: 1;
  }

  .abilities {
    display: flex;
    gap: .5rem;
    align-items: center;
    grid-column: 2;
    grid-row: 2;
  }

  .menu {
    grid-row: 1/3;
    grid-column: 3;
  }
}