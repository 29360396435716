.box {
  fill: var(--wds-color-beige-100);
  opacity: 0;
  transition: opacity .5s ease-in-out;
  touch-action: manipulation;

  &.box-visible {
    opacity: 0.2;
  }
}

.ellipsis {
  filter: blur(0.05rem);
}

.wrapper {
  width: 100%;
  height: 100%;
}

.visible {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
}

.name {
  width: 150px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
