@import 'src/styles/mixins';

.container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;

  @include hideScrollbar;
}

.wrapper {
  width: max-content;
  display: flex;
  gap: .25rem;
  align-items: center;

  &.animated {
    animation: slideInOut var(--player-clips-video-controls-timing) linear;
  }
}

.ability {
  background-color: transparent !important;
}


@keyframes slideInOut {
  10% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(var(--player-clips-video-controls-translate));
  }
  97% {
    transform: translateX(var(--player-clips-video-controls-translate));
  }
  100% {
    transform: translateX(0);
  }
}