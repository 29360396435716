@use '../../player-profile.module' as profile;

.form-margin {
  margin-bottom: 1.5rem;
}

.modal {
  @include profile.modal-form;
}

