@use 'src/styles/main';
@import 'src/styles/mixins';

@font-face {
  font-family: Protokoll;
  src: url('../public/fonts/ProtokollRegular-Web.woff') format('woff'),
    url('../public/fonts/ProtokollRegular-Web.woff2') format('woff2');
  font-weight: 400; // Regular
  font-style: normal;
}

@font-face {
  font-family: Protokoll;
  src: url('../public/fonts/ProtokollMedium-Web.woff') format('woff'),
    url('../public/fonts/ProtokollMedium-Web.woff2') format('woff2');
  font-weight: 500; // Medium
  font-style: normal;
}

@font-face {
  font-family: Protokoll;
  src: url('../public/fonts/ProtokollBold-Web.woff') format('woff'),
    url('../public/fonts/ProtokollBold-Web.woff2') format('woff2');
  font-weight: 700; // Bold
  font-style: normal;
}

@font-face {
  font-family: OldschoolGrotesk;
  src: url('../public/fonts/OldschoolGrotesk_W-Medium.woff') format('woff'),
    url('../public/fonts/OldschoolGrotesk_W-Medium.woff2') format('woff2');
  font-weight: 500; // Medium
  font-style: normal;
}

@font-face {
  font-family: OldschoolGrotesk;
  src: url('../public/fonts/OldschoolGroteskCompressed_W-Bold.woff') format('woff'),
    url('../public/fonts/OldschoolGroteskCompressed_W-Bold.woff2') format('woff2');
  font-weight: 700; // Bold
  font-style: normal;
}

@font-face {
  font-family: OldschoolGroteskCondensed;
  src: url('../public/fonts/OldschoolGroteskCondensed_W-Bold.woff') format('woff'),
    url('../public/fonts/OldschoolGroteskCondensed_W-Bold.woff2') format('woff2');
  font-weight: 700; // Bold
  font-style: normal;
}

* {
  box-sizing: border-box;

  @include mobile {
    overscroll-behavior: contain;
  }
}

body {
  margin: 0;

  @include mobile {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

:root {
  @include main.colors-theme;
  @include main.colors-light-theme;

  background-color: var(--ppapp-background);
  font-family: Protokoll, sans-serif, OldschoolGrotesk, OldschoolGroteskCondensed;
  color: #1a1a1a;
}

:root[data-theme='dark'] {
  @include main.colors-dark-theme;

  color: #fafafa;
}

.app-loader {
  margin: 1rem auto;
}
