@import 'src/styles/mixins';

.container {
  padding: 1rem;
  border: 1px solid var(--wds-on-surface-outline);
  border-radius: var(--wds-border-radius-medium);
  min-width: 13rem;
  display: flex;
  flex-flow: column;
  gap: .75rem;

  @include mobile {
    width: 100%;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: max-content;

  &.empty {
    opacity: 0.2;
  }
}

.indicator {
  box-shadow: inset 0 0 0 .125rem var(--wds-surface-background);
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: var(--wds-border-radius-medium);

  &-0 {
    background-color: var(--wds-color-green-800);
  }

  &-1 {
    background-color: var(--wds-color-green-400);
  }

  &-2 {
    background-color: var(--wds-color-green-100);
  }
}
