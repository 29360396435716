@import 'src/styles/mixins';

.layout {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @include mobile {
    overflow: initial;
    height: initial;
  }

  &-content {
    display: flex;
    flex-flow: row nowrap;
    overflow: inherit;
    padding-top: 3.75rem;
    height: 100%;

    @include mobile {
      overflow: initial;
    }
  }

  &-outlet {
    width: 100%;
    overflow-y: auto;

    @include mobile {
      overflow: initial;
    }
  }
}