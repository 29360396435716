@import 'src/styles/mixins';

.container {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  border-radius: var(--wds-border-radius-medium);
  background-color: var(--ppapp-panel-background);
  margin-bottom: 2rem;

  @include mobile {
    padding: 1rem .75rem;
    gap: 1rem;
  }
}

.image {
  height: 8rem;
  width: 6.5rem;
  overflow: hidden;
  border-radius: var(--wds-border-radius-small);
  object-fit: cover;
}

.info {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.25rem;
}

.title {
  display: flex;
  gap: 2rem;

  @include mobile {
    gap: .5rem;
  }
}

.jersey-number {
  position: relative;
  width: max-content;
  font-family: 'OldschoolGroteskCondensed' !important;
  font-size: 4rem;
  line-height: 3.5rem;

  @include mobile {
    font-size: 2rem;
    line-height: 2rem;
  }
}

.number-sign {
  position: absolute;
  top: 0;
  left: 100%;
  font-size: 1.5rem;
  line-height: 1rem;

  @include mobile {
    font-size: .5rem;
    line-height: .5rem;
  }
}

.full-name {
  font-family: 'OldschoolGroteskCondensed' !important;
  text-transform: uppercase;
  font-size: 4rem;
  line-height: 3.5rem;

  @include mobile {
    font-size: 2rem;
    line-height: 2rem;
  }
}