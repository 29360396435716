@import 'src/styles/mixins';

.container {
  width: 100%;
  padding: .75rem 2rem;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  row-gap: .75rem;

  @include mobile {
    grid-template-columns: 100%;
    padding: 0;
  }

  .player-panel {
    grid-row: 1;
    grid-column: 1;

    @include mobile {
      grid-row: 7;
      grid-column: 1;
      display: none;
    }
  }

  .share {
    grid-row: 1;
    grid-column: 3;

    @include mobile {
      grid-row: 3;
      grid-column: 1;

      padding: 0 1rem;
    }
  }

  .video {
    grid-row: 2;
    grid-column: 1/-1;

    @include mobile {
      grid-row: 1;
    }
  }

  .about {
    grid-row: 3;
    grid-column: 1/-1;

    @include mobile {
      grid-row: 4;
      grid-column: 1;
    }
  }

  .key-info {
    grid-row: 4;
    grid-column: 1/-1;

    @include mobile {
      grid-row: 5;
      grid-column: 1;
    }
  }

  .position {
    grid-row: 5;
    grid-column: 1/-1;

    @include mobile {
      grid-row: 6;
      grid-column: 1;
    }
  }

  .stats {
    grid-row: 6;
    grid-column: 1/-1;

    @include mobile {
      grid-row: 7;
      grid-column: 1;
    }
  }
}

.loader {
  margin: 1rem auto;
}

@mixin modal-form {
  width: 100% !important;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: hidden;

  @include desktop {
    max-width: 40rem !important;
    max-height: calc(100vh - 2rem) !important;
  }
  
  @include tablet {
    max-width: 25rem !important;
    max-height: calc(100vh - 2rem) !important;
  }

  @include mobile {
    padding: 1rem !important;
  }
}