@forward "./light";
@forward "./dark";

@mixin colors-theme {
  --ppapp-ability-color: var(--wds-component-states-primary-default);
  --ppapp-pitch-position-background-color: var(--wds-surface-container-highest);
  --ppapp-menu-item-background-hover: var(--wds-surface-container-low);
  --ppapp-background-paper: var(--wds-surface-background);
  --ppapp-background-paper-2: var(--wds-surface-background);
  --ppapp-panel-background: var(--wds-surface-container-lowest);
  --ppapp-highlight-card-ability-background: var(--wds-surface-container-low);

  // paper
  --ppapp-background-paper-1: var(--wds-surface-background);
  --ppapp-background-paper-2: var(--wds-surface-container-lowest);
  --ppapp-background-paper-3: var(--wds-surface-container-low);
  --ppapp-background-paper-4: var(--wds-surface-container);
  --ppapp-background-paper-5: var(--wds-surface-container-high);
  --ppapp-background-paper-6: var(--wds-surface-container-highest);
}
