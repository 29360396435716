.container {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  gap: 1rem;
  flex-flow: column nowrap;
}

.wrapper {
  max-width: 100%;
  width: max-content;
  align-self: center;
}

.controls {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}

.img {
  width: 100%;
  object-fit: contain;
}