.dropdown {
  color: var(--wds-on-surface-primary);
  font-size: var(--wds-font-size-body);
  background: var(--wds-on-surface-background);
  margin: 0 auto;

  &__disabled {
    background: transparent;
  }
}

.dropdown-control {
  padding: 0.7rem 0.625rem;
  min-height: 2.563rem !important;
  border: 1px solid var(--wds-on-surface-outline);
  border-radius: var(--wds-border-radius-small);
  cursor: pointer !important;
  transition: border-color 100ms ease-in-out, box-shadow 100ms ease-in-out;

  &:hover:not(.dropdown-control__focused) {
    border-color: var(--wds-on-surface-outline-hover);
  }

  &__focused {
    border-color: var(--wds-on-surface-primary);
    box-shadow: 0px 0px 0px 3px rgba(17, 153, 34, 0.2);
  }

  &__disabled {
    cursor: default !important;
    color: var(--wds-on-surface-secondary);
    border: 1px solid var(--wds-on-surface-outline);
  }

  &__multi {
    padding: calc(0.7rem - 4px) 1.25rem;
  }
}

.indicator {
  color: var(--wds-on-surface-outline);

  &__focused {
    color: var(--wds-on-surface-outline-hover);
  }
}

.clear-indicator {
  color: var(--wds-on-surface-secondary);

  &__focused {
    color: var(--wds-on-surface-primary);
  }
}

.menu {
  border: 1px solid var(--wds-on-surface-outline);
  background: var(--wds-surface-background);
  border-radius: var(--wds-border-radius-small);
  margin-top: 0.25rem;
  padding: 0.5rem 0.25rem;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.08);
}

.option {
  cursor: pointer !important;
  padding: 0.75rem 1rem;
  transition: background-color 100ms ease-in-out;

  &-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__disabled {
    background: none;
    cursor: default !important;
    opacity: 0.4;
  }

  &:hover {
    background-color: var(--wds-surface-container);
  }
}

.placeholder {
  color: var(--wds-on-surface-secondary);
}

.value-container {
  &-multi {
    display: flex;
    flex-flow: row wrap;
    gap: 0.25rem;
  }
}

// NOTE: Currently not used
.multi-value-container {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  color: var(--wds-surface-container);
  border-radius: var(--wds-border-radius-extrasmall);
  font-size: var(--wds-font-size-overline);
  font-weight: var(--wds-font-weight-regular);
  padding: 0.313rem 0.625rem;
  background-color: var(--wds-surface-background);
}
