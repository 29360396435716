@import 'src/styles/mixins';

.container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
}

.playstyle {
  display: block;
  flex: 1 1 calc(50% - 1rem);
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: repeat(2, auto);
  gap: .25rem;

  @include mobile {
    flex: 1 0 100%;
  }

  .radio {
    grid-row: 1/3;
    grid-column: 1;
  }

  .title {
    grid-row: 1;
    grid-column: 2;
  }

  .description {
    grid-row: 2;
    grid-column: 2;
    color: var(--wds-on-surface-secondary);
  }

  .disabled {
    opacity: .2;
  }
}
