@import 'src/styles/mixins';

.container {
  cursor: pointer;
  width: 100%;
  padding: .75rem;
  border-radius: var(--wds-border-radius-small);
  display: grid;
  grid-template-columns: 5.25rem 1fr max-content;
  grid-template-rows: repeat(3, auto);
  column-gap: .75rem;

  &.active {
    box-shadow: inset 0 0 0 1px var(--wds-on-surface-outline);
  }

  &.disabled {
    opacity: .3;
    cursor: initial;
  }
}

.thumbnail {
  grid-column: 1;
  grid-row: 1/-1;

  width: 5.25rem;
  height: 5.25rem;
  border-radius: var(--wds-border-radius-small);
  object-fit: cover;
}

.title {
  grid-column: 2 /-1;
  grid-row: 1;
  @include truncate-text;
}

.tags {
  grid-column: 2/-1;
  grid-row: 2;

  overflow-x: auto;
  display: flex;
  gap: .5rem;
  margin: .5rem 0;

  @include hideScrollbar;

  .tag {
    color: var(--wds-on-surface-secondary);
    border-radius: var(--wds-border-radius-small);
    font-size: var(--wds-font-size-caption);
    padding: 0.313rem 0.625rem;
    flex-shrink: 0;
  }
}

.duration {
  grid-column: 2;
  grid-row: 3;

  align-self: center;
  color: var(--wds-on-surface-secondary);
}

.added-indicator {
  grid-column: 3;
  grid-row: 3;

  display: flex;
  gap: .25rem;
  align-items: center;
}
