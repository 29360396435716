@use 'sass:math';
@import 'src/styles/mixins';

.container {
  --profile-video-grid-gap: 1rem;
  --playlist-expanded-width: 19.5rem;
  --playlist-collapsed-width: 5rem;

  width: max-content;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 1rem;
  margin: 0 auto;

  @include mobile {
    grid-template-columns: 100vw;
    gap: 0;
    margin: 0;
  }
}

.video-player {
  // 4rem comes from player-profile page padding
  height: calc((100vw - 4rem - var(--profile-video-grid-gap) - var(--playlist-expanded-width)) / (16 / 9));
  max-height: calc(100vh - 10rem);
  min-width: 40rem;
  aspect-ratio: 16/9;

  @include mobile {
    min-width: initial;
    width: 100%;
    height: 100vh;
    aspect-ratio: 9/16;
  }

  &.collapsed {
    height: calc((100vw - 4rem - var(--profile-video-grid-gap) - var(--playlist-collapsed-width)) / (16 / 9));

    @include mobile {
      height: 100vh;
    }
  }
}

.playlist {
  max-width: var(--playlist-expanded-width);

  @include mobile {
    height: max-content;
    max-width: initial;
    width: 100%;
  }

  &.collapsed {
    max-width: var(--playlist-collapsed-width);

    @include mobile {
      max-width: initial;
    }
  }
}

.sort-modal {
  @include mobile {
    padding: 0 !important;
    height: 100vh !important;
    overflow-y: hidden !important;
  }
}