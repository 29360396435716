.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hint {
  position: absolute;
  display: none;
  padding: .25rem .5rem;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 33%;
  height: 2rem;
  left: 33%;
  pointer-events: none;

  &.animated {
    display: flex;
    animation: moveLeftAndRightPointer 3s;
  }
}

.chevron {
  &.animated {
    animation: springEffect 300ms;
  }
}

.panner {
  appearance: none;
  width: 100%;
  height: 2rem;
  border-radius: var(--wds-border-radius-extrasmall);
  background-repeat: no-repeat;
  background-size: 100% 220%;
  background-position: 0 20%;
  outline: none;
  opacity: 0.8;
  transition: opacity .2s;

  &:active {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    width: 33%;
    height: 2rem;
    background: rgba(189, 189, 189, .5);
    border-radius: var(--wds-border-radius-extrasmall);
    box-shadow: inset 0 2px 0 0 var(--wds-color-beige-100),   /* Top */
      inset 0 -2px 0 0 var(--wds-color-beige-100),  /* Bottom */
      inset 4px 0 0 0 var(--wds-color-beige-100),   /* Right */
      inset -4px 0 0 0 var(--wds-color-beige-100);  /* Left */;
    cursor: pointer;
  }

  &.animated::-webkit-slider-thumb {
    animation: moveLeftAndRightThumb 3s;
  }
}

@keyframes moveLeftAndRightThumb {
  0%, 100% {
    transform: translateX(0);
  }

  33% {
    transform: translateX(-40%);
  }

  66% {
    transform: translateX(+40%);
  }
}

@keyframes moveLeftAndRightPointer {
  0%, 100% {
    transform: translateX(0);
  }

  33% {
    transform: translateX(-70%);
  }

  66% {
    transform: translateX(+70%);
  }
}

@keyframes springEffect {
  0% {
    transform: scale(0.5);
  }

  60% {
    transform: scale(2);
  }

  80% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
