@import 'src/styles/mixins';

.container {
  height: 100%;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto 1fr auto;

  @include mobile {
    height: initial;
    max-height: max-content;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto 1fr auto;
  }

  &.collapsed {
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto) 1fr auto;
  
    @include mobile {
      max-height: inherit;
      grid-template-columns: auto 1fr auto;
      grid-template-rows: repeat(2, max-content);
    }
  }
}

.expand {
  margin: auto;
  grid-column: 3;
  grid-row: 1;

  @include mobile {
    grid-column: 3;
    grid-row: 1;

    top: 65px;
    position: sticky;
  }

  &.collapsed {
    grid-column: 1;

    @include mobile {
      grid-column: 3;
      grid-row: 1;

      position: initial;
    }
  }
}

.reorder {
  grid-column: 1;
  grid-row: 1;

  @include mobile {
    grid-column: 1;
    grid-row: 2;
  }

  &.collapsed {
    display: none;
    grid-column: 1;
  }

  &.hidden {
    display: none;
  }
}

.sticky-background {
  display: none;

  &.collapsed {
    display: none;
  }

  @include mobile {
    grid-row: 1;
    grid-column: 1/-1;
    display: block;
    margin: -.5rem -1rem -.25rem;
    position: sticky;
    top: 60px;
  }
}

.filters {
  grid-column: 1/4;
  grid-row: 2;

  @include mobile {
    grid-column: 1/3;
    grid-row: 1;

    top: 65px;
    position: sticky;
  }

  &.collapsed {
    display: none;
    grid-column: 1;

    @include mobile {
      display: block;
      grid-column: 1/3;
      grid-row: 1;

      position: initial;
    }
  }
}

.list {
  grid-column: 1/4;
  grid-row: 3;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: .5rem;
  overflow-y: auto;

  @include hideScrollbar;

  * {
    flex-shrink: 0;
  }

  @include mobile {
    grid-column: 1/4;
    grid-row: 3;

    height: initial;
    overflow: initial;
  }

  &.collapsed {
    grid-column: 1;

    @include mobile {
      grid-column: 2/4;
      grid-row: 2;
      height: initial;
      // Change vertical list to horizontal
      overflow-y: initial;
      overflow-x: auto;
      flex-flow: row nowrap;
    }
  }
}

.add {
  margin: auto;
  grid-column: 1/4;
  grid-row: 4;

  @include mobile {
    margin: auto 0 auto auto;
    grid-column: 2/4;
    grid-row: 2;
  }

  &.collapsed {
    grid-column: 1;

    @include mobile {
      grid-column: 1;
      grid-row: 2;
      margin: auto;
    }
  }
}