@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 23rem));
  grid-template-rows: 6.725rem;
  gap: .75rem;

  @include mobile {
    grid-template-columns: 100%;
    grid-template-rows: 5.5rem;
  }
}

.item {
  cursor: pointer;
  padding: 1rem;
  border-radius: var(--wds-border-radius-extrasmall);
  display: grid;
  grid-template-columns: 8rem auto;
  gap: 1.5rem;
  overflow: hidden;

  @include mobile {
    padding: .5rem;
    grid-template-columns: 7rem auto;
    gap: .5rem;
  }

  .icon-wrapper {
    aspect-ratio: 16/9;
    border-radius: var(--wds-border-radius-extrasmall);
    margin: auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info {
    display: flex;
    flex-flow: column nowrap;
    gap: .75rem;

    @include mobile {
      gap: .5rem;
    }
  }

  .filter-title {
    @include truncate-text;
  }

  .count {
    color: var(--wds-on-surface-secondary);
  }
}
