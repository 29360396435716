@import 'src/styles/mixins';

.container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  > * {
    flex-shrink: 0;
  }
}

.text-wrapper {
  flex-shrink: initial;
}

.club-crest {
  transform: translateX(-.25rem);
}

.jersey-number {
  font-size: 3rem;
  font-weight: 900;
  margin: 0 1rem 0 .5rem;

  @include mobile {
    font-size: 2.5rem;
    margin-right: .75rem;
  }
}

.fullname {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.75rem;
  letter-spacing: -1px;
  line-height: 2.125rem;

  @include mobile {
    font-size: var(--wds-font-size-heading-5);
    line-height: 1;
  }
}
