@import 'src/styles/mixins';

:root {
  --canvas-translateX: 0px;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: .25rem;
  overscroll-behavior: none;
  overflow: hidden;
  border-radius: var(--wds-border-radius-medium);
}

.telestrations {
  aspect-ratio: 16 / 9;
  position: relative;
  border-radius: var(--wds-border-radius-large);

  @include desktop {
    width: 100%;
  }

  @include tablet {
    width: 100%;
  }

  @include mobile {
    height: calc(100vw / (9 / 16));
    max-height: calc(100vh - 4rem);
    border-radius: initial;
  }
}

.translate-canvas {
  transform: translateX(var(--canvas-translateX));
}

.controls {
  width: 100%;
  display: flex;
  padding: .75rem .5rem .5rem;
  justify-content: space-between;
  align-items: center;
  gap: .25rem;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

.confirmation {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: .5rem;
}

.cancel-button {
  position: absolute;
  top: 75%;
  left: -.825rem;
}