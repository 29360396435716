.container {
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: .5rem;
}

.title {
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
