@use "sass:math";

$font-size-heading-1: 2.5rem; /* 40px */
$font-size-heading-2: 2rem; /* 32px */
$font-size-heading-3: 1.5rem; /* 24px */
$font-size-heading-4: 1.25rem; /* 20px */
$font-size-heading-5: 1.125rem; /* 18px */
$font-size-heading-6: 1rem; /* 16px */
$font-size-body: 0.875rem; /* 14px */
$font-size-caption: 0.75rem; /* 12px */
$font-size-overline: 0.625rem; /* 10px */

@mixin average-word-width($font-size, $spacing: 0rem) {
  $aspect-ratio: math.div(3,5);
  $average-width: $font-size * $aspect-ratio * 10;
  width: $average-width + $spacing;
}

@mixin line-height-cutoff($font-size, $line-height: 1.2) {
  margin: calc(($font-size * ($line-height - 1)) / 1.1) 0;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.skeleton {
  width: var(--skeleton-width);
  height: var(--skeleton-height);
  background-color: var(--wds-on-surface-secondary);
  border-radius: var(--wds-border-radius-extrasmall);
  animation: pulse 1.5s ease-in-out infinite;
}

.text-skeleton {
  &-wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: .5rem;

    &__inline {
      display: inline-flex;
    }
  }

  &-h1 {
    height: var(--wds-font-size-heading-1);
    @include line-height-cutoff($font-size-heading-1);
    @include average-word-width($font-size-heading-1);
  }

  &-h2 {
    height: var(--wds-font-size-heading-2);
    @include line-height-cutoff($font-size-heading-2);
    @include average-word-width($font-size-heading-2);
  }

  &-h3 {
    height: var(--wds-font-size-heading-3);
    @include line-height-cutoff($font-size-heading-3);
    @include average-word-width($font-size-heading-3);
  }

  &-h4 {
    height: var(--wds-font-size-heading-4);
    @include line-height-cutoff($font-size-heading-4);
    @include average-word-width($font-size-heading-4);
  }

  &-h5 {
    height: var(--wds-font-size-heading-5);
    @include line-height-cutoff($font-size-heading-5);
    @include average-word-width($font-size-heading-5);
  }

  &-h6 {
    height: var(--wds-font-size-heading-6);
    @include line-height-cutoff($font-size-heading-6);
    @include average-word-width($font-size-heading-6);
  }

  &-body {
    height: var(--wds-font-size-body);
    @include line-height-cutoff($font-size-body);
    @include average-word-width($font-size-body);
  }

  &-caption {
    height: var(--wds-font-size-caption);
    @include line-height-cutoff($font-size-caption);
    @include average-word-width($font-size-caption);
  }

  &-overline {
    height: var(--wds-font-size-overline);
    @include line-height-cutoff($font-size-overline);
    @include average-word-width($font-size-overline);
  }
}

.button-skeleton {
  border: 0;
  border-radius: var(--wds-border-radius-full);
  display: inline-flex;
  gap: 0.5rem;

  &-sm {
    height: 2rem;
    @include average-word-width($font-size-caption, 2rem);

    &.icon {
      width: 2rem;
    }
  }

  &-md {
    height: 2.5rem;
    @include average-word-width($font-size-caption, 2.5rem);

    &.icon {
      width: 2.5rem;
    }
  }

  &-lg {
    height: 3rem;
    @include average-word-width($font-size-body, 3rem);

    &.icon {
      width: 3rem;
    }
  }
}
