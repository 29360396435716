@import 'src/styles/mixins';

.form {
  overflow-y: hidden;
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  flex: 1 1 auto;

  .content {
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    column-gap: 2rem;
    row-gap: 1rem;
    overflow-y: auto;
  
    @include mobile {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(5, auto);
    }
  }

  .controls {
    width: 100%;
    display: flex;
    gap: 1rem;
  }
}

.pitch {
  width: 100%;
  grid-column: 1;
  grid-row: 2;

  @include mobile {
    grid-row: 3;
  }
}

.positions {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  grid-column: 2;
  grid-row: 2;

  @include mobile {
    grid-column: 1;
    grid-row: 2;
  }
}

.playstyle {
  grid-column: 1 / 3;
  grid-row: 4;

  @include mobile {
    grid-column: 1;
    grid-row: 5;
  }
}

.field-label {
  grid-column: 1 / 3;

  @include mobile {
    grid-column: 1;
  }
}
