@import 'src/styles/mixins';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 1rem;
  justify-items: center;
  align-items: flex-start;
  padding-top: 28%;
  padding-bottom: 5%;

  @include mobile {
    gap: .5rem;
  }
}

.tooltip {
  animation: fade-in .4s linear forwards;
}

.position {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  background-color: var(--ppapp-pitch-position-background-color);
  transition: all .15s linear;

  &__wrapper {
    height: min(2rem, 100%);
    aspect-ratio: 1 / 1;
    border-radius: 2rem;
  }

  @include mobile {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.interactive {
  cursor: pointer;

  &:hover {
    @include pointer-device {
      box-shadow: inset 0 0 0 .125rem var(--wds-surface-background);
      background-color: var(--wds-on-surface-primary);
    }
  }
}

.active {
  box-shadow: inset 0 0 0 .125rem var(--wds-component-states-primary-default);

  &-0 {
    background-color: var(--wds-color-green-800);
  }

  &-1 {
    background-color: var(--wds-color-green-400);
  }

  &-2 {
    background-color: var(--wds-color-green-100);
  }
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}
