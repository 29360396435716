.menu {
  padding: .25rem .75rem;
  min-width: 15rem;
}

.user-wrapper {
  margin: .25rem 0 .5rem;
  display: flex;
  align-items: center;
  gap: .75rem;

  & > * {
    flex: 0 0 auto;
  }
}

.text-wrapper {
  flex: 1;
  overflow: hidden;
}

.name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.email {
  color: var(--wds-on-surface-secondary);
}
