.indicator-container {
  width: 100%;
  position: relative;
}

.indicator {
  position: absolute;
  top: -15px;
  width: 35px;
  height: 25px;
}