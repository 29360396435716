@import 'src/styles/mixins';

.container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  margin: 0;
  padding: 2rem 0;

  @include desktop {
    max-width: 60rem;
    margin: 1rem auto;
  }

  @include mobile {
    padding: 2rem 1rem;
  }
}

.title {
  font-family: 'OldschoolGroteskCondensed' !important;
  text-transform: uppercase;
}

.attributes {
  display: grid;
  gap: 1.75rem;

  @include desktop {
    grid-template-columns: repeat(3, minmax(min-content, 1fr));
  }

  @include tablet {
    gap: 1rem;
    grid-template-columns: repeat(2, minmax(min-content, 1fr));
  }

  @include mobile {
    grid-template-columns: repeat(1, minmax(min-content, 1fr));
  }
}

.attribute {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  padding: 1.5rem;
  border: 1px solid var(--wds-on-surface-outline);
  border-radius: var(--wds-border-radius-medium);
}

.generic-unit {
  display: flex;
  gap: .5rem;

  .secondary {
    color: var(--wds-on-surface-secondary);
  }
}

.edit-button {
  margin: 1rem auto;

  @include mobile {
    margin-left: 0;
  }
}
