.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(var(--steper-grid-columns), minmax(min-content, 1fr));
  column-gap: .5rem;
  align-items: center;
}

.step {
  width: 100%;
  text-transform: capitalize;
  border-bottom: 1px solid var(--wds-on-surface-outline);
  color: var(--wds-on-surface-secondary);
  padding-bottom: .3125rem;

  &__active {
    border-bottom: 1px solid var(--wds-on-surface-outline-hover);
    color: var(--wds-on-surface-primary);
  }
}
