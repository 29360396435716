@import 'src/styles/mixins';

.container {
  width: calc(100% - 4rem);
  overflow: hidden;
  margin: 2rem;
  border-radius: var(--wds-border-radius-large);
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 50%), url('../../../../../src/styles/images/football_player.webp');
  background-size: cover;
  background-position-y: 40%;
  background-repeat: no-repeat;
  aspect-ratio: 16 / 7;

  @include mobile {
    margin: 1rem;
    width: calc(100% - 2rem);
    aspect-ratio: 9 / 16;
  }
}

.content {
  width: 100%;
  height: 100%;
  padding: 2rem;
  backdrop-filter: blur(1rem);
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    color: inherit;
  }
}