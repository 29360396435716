@import 'src/styles/mixins';

.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  height: calc((100vw - 4rem) / (16 / 9));
  max-height: calc(100vh - 10rem);
  aspect-ratio: 16/9;
  margin: auto;

  @include mobile {
    width: 100%;
    height: calc(100vh - 10rem);
    aspect-ratio: 9/16;
  }
}

.text {
  text-align: center;
}