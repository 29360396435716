.container {
  position: absolute;
  top: 0;
  width: 100%;

  &.bottom {
    top: initial;
    bottom: 0;
  }
}

.positioner {
  flex-shrink: 0;
}

.wrapper {
  padding: .5rem 1rem .5rem .5rem;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  width: max-content;
  max-width: calc(100% - 1rem);
  gap: .5rem;
  border-radius: var(--wds-border-radius-small);
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--wds-color-beige-100);
}

.hint {
  flex: 1 1 auto;
}
