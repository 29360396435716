@import 'src/styles/mixins';

$seekbarTrackHeight: .25rem;
$seekbarThumbSize: 1rem;
$trackBorderRadius: calc($seekbarTrackHeight / 2);

.container {
  position: relative;
  width: 100%;
  height: .75rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &.desktop:hover,
  &:active {
    .slider::-webkit-slider-thumb {
      transform: scale(1);
    }
  }
}

.progress {
  position: absolute;
  height: $seekbarTrackHeight;
  border-radius: $trackBorderRadius;
  bottom: .125rem;
  background-color: var(--wds-on-surface-primary);
  pointer-events: none;
}

.slider {
  appearance: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $seekbarTrackHeight;
  border-radius: $trackBorderRadius;
  background-color: var(--wds-on-surface-secondary);
  outline: none;
  transition: opacity .2s;

  &:hover {
    @include desktop {
      cursor: pointer;
    }
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: $seekbarThumbSize;
    height: $seekbarThumbSize;
    border-radius: var(--wds-border-radius-full);;
    background: var(--wds-on-surface-primary);
    transform: scale(0);
    transition: transform 80ms ease-in-out;
  }
}
