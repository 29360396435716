@import 'src/styles/mixins';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: var(--wds-border-radius-medium);
  background-color: var(--wds-surface-background);
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  overflow: hidden;

  @include mobile {
    border-radius: var(--wds-border-radius-extrasmall);
  }
}

.controls {
  width: 100%;
  padding: 0 2rem;
  margin-top: auto;
  transition: .3s;
  opacity: 1;
  color: var(--wds-color-beige-100);
  transition: opacity .1s linear;

  &__hidden {
    opacity: 0;
    pointer-events: none;
  }

  &__mobile {
    opacity: 1;
    pointer-events: auto;
    padding: 1rem 1rem .25rem 1rem;
  }
}

.video-container {
  position: absolute;
  transition: transform 150ms linear;

  .video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow-clip-margin: content-box;
    overflow: clip;
  }

  .video-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-flow: column;
  transition: .5s;

  &__fullscreen {
    display: none;
  }

  &__tinted {
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 20%);
  }
}

.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(1.5rem)
}
