@import 'src/styles/mixins';

.container {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 1rem;
}

.table {
  display: grid;
  grid-template-columns: auto auto auto max-content;
  gap: 0;

  @include mobile {
    grid-template-columns: 40% auto auto max-content;
  }

  .full-row {
    grid-column: 1/-1;
  }

  .header-cell {
    background-color: var(--ppapp-panel-background);
    position: sticky;
    padding: .5rem 2rem;
    top: 0;
    z-index: 1;
    display: flex;

    @include mobile {
      padding: .5rem;
    }
  }

  .content-cell {
    padding: 2rem;
    display: flex;
    align-items: center;
    gap: .5rem;

    @include mobile {
      padding: .5rem;
    }
  }

  .align-center {
    justify-content: center;
  }
}

.team-selector {
  padding: .5rem 1rem;
  background-color: var(--ppapp-panel-background);
  border-top-right-radius: var(--wds-border-radius-medium);
  border-top-left-radius: var(--wds-border-radius-medium);

  @include mobile {
    padding: .5rem;
  }
}

.truncated-text {
  @include mobile {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
