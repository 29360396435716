@import 'src/styles/mixins';

.container {
  display: grid;
  grid-template-columns: minmax(30rem, 50rem) 22rem;
  grid-template-rows: minmax(25rem, 1fr) max-content;
  max-height: calc(100vh - 10rem);
  column-gap: 1rem;
  row-gap: 2rem;

  @include mobile {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    column-gap: 0;
    row-gap: 1rem;
    overflow: hidden;
  }
}

.video {
  aspect-ratio: 16/9;
}

.actions {
  grid-column: 1/-1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-wrapper {
  max-height: 40rem;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;

  @include mobile {
    padding: 0;
    max-height: initial;
  }
}

.infinite-list {
  flex: 1 1 100%;
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  gap: .5rem;

  @include hideScrollbar();
}

.skeleton {
  margin-bottom: .5rem;
  flex-shrink: 0;
}