@import 'src/styles/mixins';

.container {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}

.modal {
  @include mobile {
    padding: .5rem !important;
  }
}

.update-button {
  margin-left: auto;
}