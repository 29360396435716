.team-selector-item {
  &__button {
    align-items: center;
    appearance: none;
    background: none;
    border-radius: var(--wds-border-radius-medium);
    border: 0;
    color: var(--wds-on-surface-primary);
    cursor: pointer;
    display: flex;
    gap: 1rem;
    height: 3rem;
    margin: 0;
    padding: 1rem;
    text-align: left;
    transition: background-color 150ms ease-in-out;
    width: 100%;

    &--collapsed {
      gap: 2rem;
      padding: 0.5rem;
    }

    &:hover {
      background-color: var(--wds-surface-container);
    }

    &:active {
      background-color: var(--wds-surface-container);
    }

    &:disabled {
      cursor: default;
      opacity: 0.4;

      &:hover,
      &:active {
        background: none;
      }
    }
  }

  &__crest {
    height: 2rem;
    width: 2rem;
  }

  &__name {
    flex: 1;
  }
}
