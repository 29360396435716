.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.menu-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logo {
  text-decoration: none;
  width: 3.375rem;
}