@import 'src/styles/mixins';

.container {
  width: 100%;
  max-width: 75rem;
  margin: 2rem auto;

  @include mobile {
    width: initial;
    margin: 2rem 1rem;
  }
}

.public-container {
  width: 100%;
  display: grid;
  flex-flow: column;
  grid-template-columns: auto minmax(max-content, 25rem);
  column-gap: 10rem;

  @include mobile {
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, auto);
    row-gap: 2rem;
  }
}

.info {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  margin: auto 0;
  width: 100%;
  max-width: 30rem;
}

.pictures {
  width: calc(100% - 2rem);
  max-width: 25rem;

  @include mobile {
    max-width: initial;
  }
}

.actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.image {
  width: 100%;
  aspect-ratio: 1;
  border-radius: var(--wds-border-radius-small);
  object-fit: cover;
  overflow: hidden;
}

.authenticated-container {
  width: 100%;
  max-width: 75rem;
  display: grid;
  flex-flow: column;
  margin: 2rem auto;

  @include mobile {
    grid-template-columns: 100%;
    margin: 2rem 1rem;
  }
}

.authenticated-content {
  display: flex;
  flex-flow: column nowrap;
  gap: 2.5rem;
  margin: auto 0;
  width: 100%;
  max-width: 75rem;
}

.top-section {
  background-color: var(--wds-surface-background);
}

.top-section-content {
  display: flex;
  flex-flow: row nowrap;
  gap: 2rem;
  margin: 2rem;
}

.name-cta {
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 1.5rem;
}

.picture {
  position: relative;
  display: flex;
  flex-flow: column nowrap;

  .image {
    width: 6.5rem;
    aspect-ratio: 4 / 5;
    object-fit: cover;
    background-color: var(--ppapp-panel-background);
    border-radius: var(--wds-border-radius-medium);
  }

  .initials {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'OldschoolGroteskCondensed' !important;
    font-size: 7rem;
    font-weight: bold;
  }

  .edit-picture {
    margin: 0 auto;
  }
}

.jersey-wrapper {
  top: 0;
  right: 0;
  text-shadow: 0 4px 4px rgba(0, 0, 0, .5);

  &.hidden {
    display: none;
  }

  @include mobile {
    right: 2rem;
  }
}

.jersey-number {
  position: relative;
  font-family: 'OldschoolGroteskCondensed' !important;
  font-size: 3rem;
  line-height: 3.5rem;
}

.number-sign {
  position: absolute;
  top: 0;
  left: 100%;
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.name {
  grid-column: 2;
  grid-row: 1;

  @include mobile {
    grid-column: 1;
    grid-row: 1;
  }

  .wrapper {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
  }

  .player-name {
    font-family: 'OldschoolGroteskCondensed' !important;
    text-transform: uppercase;
    font-size: 4.25rem;
    line-height: 3.5rem;

    @include desktop {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }
}
