$mobile: 479px;
$desktop: 960px;

@mixin mobile {
  @media (max-width: $mobile) { @content; }
}

@mixin tablet {
  @media (min-width: ($mobile + 1)) and (max-width: ($desktop - 1)) { @content; }
}

@mixin desktop {
  @media (min-width: $desktop) { @content; }
}

@mixin hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin reset-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin paper {
  border-radius: var(--wds-border-radius-large);
  background: var(--ppapp-background-paper);
  padding: 1rem;
}

@mixin touch-device {
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}

@mixin pointer-device {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin truncate-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
