.container {
  display: flex;
  flex-flow: column nowrap;
  gap: .5rem;
  align-items: center;
}

.accent {
  color: var(--wds-on-surface-secondary);
}
