@import 'src/styles/mixins';

.container {
  display: grid;
  grid-template-columns: minmax(30rem, 50rem) 22rem;
  grid-template-rows: minmax(25rem, 1fr);
  max-height: calc(100vh - 15rem);
  column-gap: 1rem;
  overflow: hidden;

  @include mobile {
    grid-template-columns: 100%;
    row-gap: 1rem;
    grid-template-rows: auto;
  }
}

.video-wrapper {
  aspect-ratio: 16/9;
}

.info {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  overflow-y: auto;

  @include mobile {
    padding: 0;
  }
}