.clubhouse-item {
  text-decoration: none;

  &--selected,
  &--selected:hover,
  &--selected:focus,
  &--selected:active {
    // TODO: Implement selection of menu list items in the Web Design System and remove this
    border-radius: var(--wds-border-radius-extrasmall);
    background: var(--wds-surface-container);
  }
}
