.new-clubhouse {
  align-items: center;
  background: none;
  border-radius: var(--wds-border-radius-extrasmall);
  border: 0;
  color: var(--wds-on-surface-primary);
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  height: 3rem;
  margin: 0;
  padding: 1rem;
  text-align: left;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: var(--wds-surface-container);
  }

  &:active {
    background-color: var(--wds-surface-container);
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;

    &:hover,
    &:active {
      background: none;
    }
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    font-size: 2rem;
  }
}
