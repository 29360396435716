.container {
  padding: 1rem;
  border-radius: var(--wds-border-radius-small);
}

.elevation-0 {
  background-color: transparent;
}

.elevation-1 {
  background-color: var(--ppapp-background-paper-1);
}

.elevation-2 {
  background-color: var(--ppapp-background-paper-2);
}

.elevation-3 {
  background-color: var(--ppapp-background-paper-3);
}

.elevation-4 {
  background-color: var(--ppapp-background-paper-4);
}

.elevation-5 {
  background-color: var(--ppapp-background-paper-5);
}

.elevation-6 {
  background-color: var(--ppapp-background-paper-6);
}