.container {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  gap: .75rem;
  align-items: center;
}

.list {
  display: flex;
  flex-flow: row wrap;
  gap: .5rem;
  justify-content: center;
}