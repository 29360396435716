@import 'src/styles/mixins';

.container {
  width: 100%;
  max-width: 80rem;
  margin: 2rem auto;
  background-image:
    linear-gradient(89.8deg, var(--ppapp-background) 0.19%, rgba(0, 0, 0, 0) 97.44%),
    linear-gradient(0deg, var(--ppapp-background) 0.19%, rgba(0, 0, 0, 0) 97.44%),
    url('../../../../styles/svg/mesh_tinted.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 589px 519px;

  display: grid;
  grid-template-columns: minmax(auto, max-content) auto minmax(auto, max-content);
  grid-template-rows: repeat(2, auto);
  column-gap: 4rem;
  row-gap: 2rem;

  overflow: hidden;

  @include tablet {
    grid-template-columns: repeat(3, minmax(auto, max-content));
    column-gap: 2rem;
  }

  @include mobile {
    padding: 0 1rem;
    background-position: top right;

    grid-template-columns: 100%;
    grid-template-rows: repeat(5, auto);
    row-gap: 1rem;
    column-gap: 1rem;
  }
}

.picture {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 1rem;
  grid-column: 1;
  grid-row: 1 / 3;

  @include mobile {
    width: 100%;
    grid-column: 1;
    grid-row: 2;
  }

  .jersey-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    text-shadow: 0 4px 4px rgba(0, 0, 0, .5);

    &.hidden {
      display: none;
    }

    @include mobile {
      right: 2rem;
    }
  }

  .jersey-number {
    position: relative;
    font-family: 'OldschoolGroteskCondensed' !important;
    font-size: 7rem;
    line-height: 6rem;
    
    @include desktop {
      transform: translateX(30%);
    }
  }

  .number-sign {
    position: absolute;
    top: 0;
    left: 100%;
    font-size: 2.5rem;
    line-height: 2rem;
  }
  
  .image {
    width: 100%;
    max-width: 24rem;
    aspect-ratio: 4 / 5;
    object-fit: cover;
    background-color: var(--ppapp-panel-background);
    border-radius: var(--wds-border-radius-medium);
  }

  .initials {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'OldschoolGroteskCondensed' !important;
    font-size: 7rem;
    font-weight: bold;
  }

  .edit-picture {
    margin: 0 auto;
  }
}

.name {
  grid-column: 2;
  grid-row: 1;

  @include mobile {
    grid-column: 1;
    grid-row: 1;
  }

  .wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
  }

  .player-name {
    font-family: 'OldschoolGroteskCondensed' !important;
    text-transform: uppercase;
    font-size: 4.25rem;
    line-height: 3.5rem;

    @include desktop {
      font-size: 7rem;
      line-height: 6rem;  
    }
  }
}

.social {
  grid-column: 3;
  grid-row: 1;

  @include mobile {
    grid-column: 1;
    grid-row: 3;
  }

  .icons {
    display: flex;
    gap: 1rem;
    padding: 1rem;

    @include mobile {
      padding: 1rem 0;
    }
  }
}

.about {
  grid-column: 2 / 4;
  grid-row: 2 / 3;

  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  padding-right: 4rem;

  @include tablet {
    padding-right: 1rem;
  }

  @include mobile {
    grid-column: 1;
    grid-row: 4;

    padding-right: 0;
  }

  .heading {
    font-family: 'OldschoolGroteskCondensed' !important;
    text-transform: uppercase;
  }

  .text {
    width: 100%;
    max-width: 42rem;

    @include tablet {
      max-width: 24rem;
    }
  }

  .edit-about {
    margin-right: auto;
  }
}
