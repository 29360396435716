@import 'src/styles/mixins';

.container {
  height: inherit;
  max-width: 100%;
  width: var(--playlist-expanded-width);
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: hidden;
  gap: 1rem;

  @include mobile {
    width: 100%;
  }
}

.header {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(max-content);
  gap: .5rem;

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.list {
  flex: 1 1 100%;
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  gap: .5rem;
}

.item {
  cursor: grab;
}

.dragging {
  cursor: grabbing;
  opacity: .8;
  transform: scale(.95);
}

.over {
  opacity: .3;
}