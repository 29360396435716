.form {
  width: 100%;
  overflow-y: hidden;
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  flex: 1 1 100%;

  .content {
    display: flex;
    flex-flow: row wrap;
    gap: 1.5rem;
    overflow-y: auto;
  }

  .controls {
    display: flex;
    gap: 1rem;
  }
}

.field {
  flex: 0 0 100%;

  &__small {
    flex: 1 1 calc(50% - 1rem);
  }
}
