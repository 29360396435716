.form {
  overflow-y: hidden;
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  flex: 1 1 100%;

  .content {
    display: flex;
    flex-flow: row wrap;
    gap: 1.5rem;
    overflow-y: auto;
  }

  .controls {
    flex-shrink: 0;
    display: flex;
    gap: 1rem;
  }
}

.field {
  flex: 0 0 100%;
}

.field-small {
  flex: 0 0 calc(50% - .75rem);
}